.footerContainer {
    padding-top: 40px;
    padding-bottom: 40px;
    background-color: black;
    display: flex;
    column-gap: 40px;
    justify-content: center;
    color: white;
    font-size: 14px;
    align-items: center;
    overflow: hidden;
}
.footerSection {
    text-align: left;
}
.footerSection > * {
    margin: 0;
}
.footerSection>h3 {
    margin: 0;
} 
.pageLinks {
    color: #828282;
    font-weight: normal;
    font-size: 14px;
    margin: 0;
}
.footerText {
    color: #828282;
}
.footerSide {
    display: flex;
    column-gap: 40px;
}

.footerLogo {
    width: 270px;
    height: 100px;
}

a {
   text-decoration: none;
}

@media only screen and (max-width: 650px) {
    .footerSide {
        display: flex;
        column-gap: 15px;
        margin-left: 15px;
        margin-right: 15px;
        justify-content: center;
    }
    .footerContainer {
        display: flex;
        row-gap: 20px;
        flex-direction: row;
    }
    .footerLogo {
        display: none;
    }
}