
.clientItem {
    width: 150px;
    box-shadow: 0px 0px 12px -3px rgba(0,0,0,0.35);
    padding: 10px;
    border-radius: 16px;
}
.clientItem>p {
    font-size: 13px;
}
.clientItem>h3 {
    font-size: 15px;
}
.clientItem>h2 {
    font-size: 15px;
}
.clientItems {
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    column-gap: 12px;
    justify-content: center;
}
.clientImage {
    width: 150px;
    height: 126px;
    display: flex;
    align-items: center;
}
.clientImage>img {
    width: 100%;
}
