.clientSectionContainer {
    width: 100vw;
    height: 150px;
    background-color: black;
    display: flex;
    flex-direction: row;
    justify-content: center;
    column-gap: 50px;
}

.projectsContainer {
    background-color: black;
    color: white;
    padding-top: 20px;
    padding-bottom: 200px;
}
.projectItems {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100vw;
}
.project {
    width: 200px;
    height: 100%;
    margin: 0px;
    filter: grayscale(100%);
    cursor: pointer;
    transition-duration: 0.4s;
    display: flex;
    justify-content: center;
    align-items: center;
    
}
.project:hover {
    cursor: pointer;
    filter: grayscale(0%);
}
.projectStyle {
    display: flex;
    flex-direction: row;
    width: 25%;
}

.projectImg {
    width: 60%;
}
@media only screen and (max-width: 650px) {
    .clientSectionContainer {
        height: 120px;
    }
}

@media only screen and (max-width: 450px) {
    .clientSectionContainer {
        column-gap: 10px;
    }
    .project {
        width: 130px;
    }
}