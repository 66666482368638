.contactSectionContainer {
    width: 100%;
    height: 800px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: black;
    padding-bottom: 40px;
}
.contactTitle {
    color: white;
    text-transform: uppercase;

}
.contactItems {
    display: flex;
    flex-direction: column;
    column-gap: 100px;
    flex-wrap: wrap;
    margin-bottom: 50px;
    align-items: center;
}
.contactItems>h3 {
    color: white;
    font-size: 50px;
}
.contactText {
    font-size: 80px;
    color: white;
    margin: 0;
    margin-bottom: 20px;
}

.emailBox {
text-align: center;
 display: flex;
 flex-direction: row;
 width: 500px;
 border: 1px solid white;
 border-radius: 10px;
 align-items: center;
 transition-duration: 0.4s;
 
}
.emailBox:hover {
    background-color: white;
    color: black;
    cursor: pointer;
}
.emailBox:hover::after {
    content: "Click To Copy";
    font-weight: bold;
    font-size: 25px;
  }
.emailBoxCopied {
content: "";
text-align: center;
display: flex;
flex-direction: row;
width: 500px;
background-color: white;
border: 1px solid white;
border-radius: 10px;
align-items: center;
transition-duration: 0.4s;
}

.emailBoxCopied h2 {
    display: none;
}
.emailBoxCopied::after {
    content: "Copied";
    font-weight: bold;
    font-size: 25px;
  }
.emailBox:hover h2  {
    display: none
  }

.emailIconBox {
    width: 80px;
    background-color: white;
    border-radius: 10px;
}

.emailTextBox {
    margin-left: 20px;
    text-align: center;
}

.emailText {
    color: white;
    font-size: 25px;
    margin-left: 20px;
    text-transform: lowercase;
}
.info {
    width: 400px;
    height: 350px;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-bottom: 1px solid black;
    border-radius: 10px;
    box-shadow: -5px 4px #CF4747;
}
.infoContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
    align-self: center;
}
.infoCardTop {
    width: 100%;
    height: 4vh;
    border-bottom: 1px solid black;
    border-radius: 10px;
    margin-bottom: 20px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.mailIcon {
    font-size: 40px;
    padding: 12px;
    transform: translate(-1px, 3px);
}

.contactCardTop {
    width: 100%;
    height: 3vh;
    border-bottom: 1px solid black;
    border-radius: 10px;
    margin-bottom: 20px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.infoTitle {
    margin: 0;
}

.infoButton {
        background-color: white;
        border: .5px solid black;
        border-radius: 10px;
        font-weight: bold;
        padding: 15px 32px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        margin: 4px 2px;
        cursor: pointer;
        box-shadow: -5px 4px #CF4747;
        transition-duration: 0.4s;
}

.infoButton:hover {
    background-color: #f2f3f4;
}

@media only screen and (max-width: 450px) {
    .contactItems {
        flex-direction: column;
    }
    .info {
        width: 90%;

    }
    .infoTitle {
        font-size: 25px;
    }
    .contactText {
        font-size: 50px;

        margin-top: 50px;
    }
    .mobileContact {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

    }
    .emailBox, .emailBoxCopied {
        text-align: center;
         display: flex;
         flex-direction: row;
         width: 350px;
         border: 1px solid white;
         border-radius: 10px;
         align-items: center;
         transition-duration: 0.4s;
         
        }
    .emailText {
        font-size: 15px;
    }
    .mailIcon {
        font-size: 30px;
        padding: 12px
    }
}

.contactPageContainer {
    background-color: black;
}

@media only screen and (max-width: 650px) {
    .contactSectionContainer {
        height: 90vh;
    }
}