.serviceSectionContainer {
    width: 100%;
    height: 45vh;
    background-color: black;
    padding-top: 30px;

}

.serviceSectionContainer>h2 {
    text-align: center;
    margin-bottom: 30px;
}
.cardContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 35px;
    margin-top: 40px;
}

.serviceCard {
    width: 200px;
    height: 280px;
    border-radius: 10px;
    background-color: white;
}

.serviceSectionContainer>h2 {
    color: white;
    margin: 0;
    margin-bottom: 20px;
}

.card1 {
    background-image: url('../../../Images/Card1.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.card2 {
    background-image: url('../../../Images/Card2.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.card3 {
    background-image: url('../../../Images/Card3.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.card4 {
    background-image: url('../../../Images/Card4.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

@media only screen and (max-width: 650px) {
    .serviceSectionContainer {
        height: auto;
    }
    .cardContainer {
        display: flex;
        flex-direction: column;
        row-gap: 50px;
    }
}