.whyContainer {
    overflow: hidden;
}

.whyPageItems {
    display: flex;
    flex-direction: column;

}

.whyPageItem {
    width: 100vw;
    height: 100vh;
    background-image: url('../../Images/grainBackground.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    flex-direction: row;
}

.whyItemInner {
    width: 60vw;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: bold;
    display: flex;
    flex-direction: column;
    
}
.whyItemInner>h3 {
    font-weight: bold;
    font-size: 25px;
}
.whyItemInner>p {
    width: 80%;
    opacity: 85%;
    text-align: center;
}

.whyItemBg {
    width: 40vw;
    background-color: rgba( 119, 0, 255, 0.4);
}

.countUp {
    margin-top: 60px;
    font-size: 35px;
}

.whyImage {
    width: 70%;
}

@media only screen and (max-width: 650px) {
    .whyPageItem {
        display: flex;
        flex-direction: column;
    }

    .whyItemInner {
        width: 100vw;
    }

    .countUp {
        margin-bottom: 50px;
    }

    .whyItemInner>p {
        text-align: center;
    }
    .whyItemInner>h3 {
        text-align: center;
    }
}