.navContainer {
    display: flex;
    height: 60px;
    width: 50vw;
    align-items: center;
    column-gap: 20px;
    margin-left: 100px;
    font-size: 16px;
    font-weight: bold;
    

}

a {
    text-decoration: none;
    color: white;
}

.otherPageNav  {
    color: white;
    background-color: white;
}
.otherPageNav>.navContainer>a {
    color: white;
}

.otherPageNav>a {
    color: white;
}





.navLayout {
    display: flex;
    justify-content: center;
}
.navLogo {
    width: 130px;
}
.navbarLinks {
    display: flex;
    justify-content: center;
    column-gap: 25px;
    font-size: 12px;
    align-items: center;
}

.navbarLinks>h2 {
    font-size: 10px;
    margin: 0;
}
.navbar {
    margin-top: 10px;
    align-self: center;
    display: flex;
    flex-direction: row;
    column-gap: 12px;
    font-size: 10px;
    width: 100vw;
    justify-content: center;
    position: relative;
    z-index: 500;
}
.navLink {
    display: flex;
    align-items: center;
    flex-direction: row;
    font-size: 10px;
    justify-content: center;
    z-index: 500;
}
.navLinks {
    color: black;
    text-transform: uppercase;
    opacity: 80%;
    transition: ease-in-out;
    font-size: 16px;
    transition-duration: .2s;
}
.navLinks:hover {
    opacity: 100%;
    cursor: pointer;
}

.navContainer {
    margin-top: 10px;
    margin-bottom: 30px;
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    z-index: 1000;
}
.navbarContainer {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    column-gap: 10px;
    width: 90vw;
}
.navItems {
    display: flex;
    flex-direction: row;
    column-gap: 20px;
    font-size: 10px;
    align-content: center;
}

.topLogo {
    opacity: 50%;
    width: 13vh;
    height: auto;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url('');
    align-content: flex-start;
}

.nav-btn {
    padding: 5px;
    cursor: pointer;
    background:transparent;
    border: none;
    outline: none;
    color: black;
    opacity: 0;
    visibility: hidden;
    font-size: 1.8rem
}
.navMobileItems {
    display: flex;
    justify-content: center;
    width: 13vh;
    height: 100%;
    visibility: hidden;
}

@media only screen and (max-width: 650px) {
    .navLogo {
        display: none;
    }
    
    .navbarContainer {
        display: flex;
        justify-content: center;
        column-gap: 20px;
    }

    .navbarLinks {
        display: flex;
        flex-direction: column;
        row-gap: 25px;
        color: white;
    }

    .navMobileItems {
        width: 13vh;
        height: 100%;
        visibility: visible;
    }
    .nav-btn {
        visibility: visible;
        opacity: 1;
    }
    .nav-btn {
        color: white;
    }
    .navbar {
        align-self: center;
       justify-content: space-between;
       width: 100vw;


    }

    nav {
		position: fixed;
		top: 0;
		left: 0;
		height: 100vh;
		width: 100vw;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		gap: 1.5rem;
		background-color: black;
		transition: 1s;
		transform: translateY(-101vh);
        z-index: 1000;
    }

    .responsive_nav {
        transform: none;
    }

    nav .nav-close-btn {
		position: absolute;
		top: 2rem;
		right: 2rem;
    }
    .navLink {
        flex-direction: column;
    }

    @media only screen and (min-width: 1025px) {
        .navMobileItems {
            display: none;
        }
    }
    
}

.otherPageNav {
    background-color: #151515  ;
    height: 90px;
    width: 100vw;
    margin-top: -10px;
}
.otherPageNav>*>*>*>*>*>*>* {
    color: white;
}

@media only screen and (max-width: 650px) {
    .otherPageNav{
        margin-top: -10px;
    }
    .navLinks {
        color: white;
    }
    .nav-btn {
        color: black
    }
    .otherPageNav>*>*>*>*>* {
        color: white;
    }
    
}
