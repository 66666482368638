.whySectionContainer {
    height: 800px;
    background-image: url('../../../Images/grainBackground.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}
.whyLeft {
    width: 75vw;
    height: 800px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}
.whyLeft>h3 {
    font-size: 25px;
}
.whyItem {
    display: flex;
    flex-direction: column;
}
.whyItems {
    display: flex;
    height: 100vh;
}
.whyItem>h2 {
    font-size: 33px;
    width: 560px;
    margin: 0;
}

h2>span {
    color: #7700FF;
}

.whyItem>p {
    font-size: 15px;
    width: 500px;
}

.whyButton {
    width: 145px;
    height: 35px;
    background-color: black;
    color: white;
    border: 0;
    border-radius: 8px;
    font-weight: bold;
}

.whyRight {
    width: 50vw;
    height: 800px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.whyColor {
    background-color: rgba(120, 0, 224, 0.4);
    height: 800px;
    z-index: 0;
}

.whyRight>img {
    width: 85%;
}
@media only screen and (max-width: 650px) {
    .whySectionContainer {
        height: 535px;
    }
    .whyItems {
        height: 680px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
    }
    .whyItem>h2 {
        font-size: 25px;
        width: 350px;
        margin: 0;
        margin-bottom: 20px;
    }
    .whyLeft>h3 {
        font-size: 25px;
    }
    .whyItem>p {
        font-size: 12px;
        width: 350px;
        margin-bottom: 30px;
    }
    .whyLeft {
        margin-bottom: 120px;
        height: 535px;
    }
    .whyRight {
        display: none;
    }
}