.heroContainer {
    width: 100vw;
    height: 95vh;
    background-image: url('../../../Images/grainBackground.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.heroItems {
    display: flex;
    justify-content: center;
    column-gap: 150px;
    margin-top: -50px;
}
.logo {
    width: 300px;
    margin-top: 80px;
}

.heroItem {
    margin: 0;
}

.heroItem>h3 {
    margin: 0;
    font-size: 26px;
    
}
.heroItem>h1 {
    margin: 0;
    color: #7700FF;
    font-size: 35px;
}
.buttonContainer {
    display: flex;
    column-gap: 10px;
    margin-top: 10px;
}
.heroButton {
    width: 145px;
    height: 35px;
    background-color: black;
    color: white;
    border: 0;
    border-radius: 8px;
}
.heroSquare {
    width: 160px;
    height: 170px;
    border-radius: 20px;
}
.squareItems {
    display: flex;
    column-gap: 20px;
    margin-bottom: 20px;
    margin-top: 20px;
}
.square1 {
    background-image: url('../../../Images/heroCard1.png');
    background-position: center;
    background-size: cover;
}
.square2 {
    background-image: url('../../../Images/small2.png');
    background-position: center;
    background-size: cover;
}
.square3 {
    background-image: url('../../../Images/phonecard.png');
    background-position: center;
    background-size: cover;
}
.square4 {
    background-image: url('../../../Images/heroCard4.png');
    background-position: center;
    background-size: cover;
}
@media only screen and (max-width: 650px) {
    .heroContainer {
        height: 90vh;
    }
    .heroItems {
        display: flex;
        flex-direction: column;
        align-items: center;
        row-gap: 60px;
        margin-bottom: 100px;
    }
    .heroSquare {
        width: 110px;
        height: 120px;
        border-radius: 20px;
    }
    .heroItem>h3 {
        margin: 0;
        font-size: 22px;
        
    }
    .heroItem>h1 {
        margin: 0;
        color: #7700FF;
        font-size: 28px;
    }
    .logo {
        width: 240px
    }
    .heroContainer {
        height: 85vh;
    }
}

.homeContainer{
    width: 100vw;
    overflow: hidden;
}